<template>
    <v-select
        :items="items"
        :disabled="disabled"
        label="Priorità"
        outlined
        v-model="currentIndex"
        item-text="Name"
        item-value="IdCategory"
        placeholder="Seleziona la priorità"
        :rules="[valueRules]"
    >
    </v-select>
</template>

<script>

export default {
    props: ["farmId", "model", "disabled"],

    data: () => ({
        valueRules: v => {
            if (parseInt(v) >= 0) return true;
            return "Valore Richiesto"
        },
        items: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ]
    }),

    computed: {

        currentIndex: {
            get() {
                return this.model;
            },
            set(newValue) {
                this.$emit("update:model", newValue);
            }
        }
    },

    methods: {
    }
};
</script>

<style scoped>
.image-category {
    height: 38px;
    width: auto;
}
</style>
